<template>
  <div class="">
   <v-card >
    <v-app-bar
      absolute
      color="dark"
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-6"
    >
      <v-container>
        <div class="row pt-2">
          <div class="col-md-9 col-9">
            <v-img
              height="36px"
              max-width="80px"
              :src="backgroundImage"
            ></v-img>
          </div>
          <div class="col-md-3 col-3 ">
            <!-- <v-spacer></v-spacer> -->

            <v-btn class="ma-1"      text   href="https://boraticketstore.com.br/">
              Eventos
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-app-bar>

  </v-card>

    <img  class="ba" src="banner-heade.jpeg" alt="" />
    <div  style="margin-top: 80px;" class=" pr-5 pl-5 py-0">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-5 mb-5 mb-lg-0">
            <h2 class="text-white font-weight-light font-bold font-weight-black">
             Cadastre Seu Evento
             <br>
             e ai BORA !
            </h2>
            <p class="lead text-white mt-2 font-weight-400">
              Crie e viva experiências únicas quando e onde estiver.
            </p>
          </div>
          <div class="col-lg-6 mb-sm-auto"></div>
        </div>
      </div>
 
    <div style=""
      class=" row justify-content-center align-items-md-center text-uppercase"
    >
      <vue-confirm-dialog></vue-confirm-dialog>

      <div class="col-md-12" style="">
        <form class="form">
          <v-stepper v-model="e6" vertical>
            <v-stepper-step :complete="e6 > 1" step="1">
              Cadastre seu Evento
              <small>Insira os dados a seguir</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card color="grey lighten-1" class="mb-12" elevation="8">
                <v-card-title class="card-header">
                  <h3 class="mb-0">
                    <h3 class="mb-0">
                       {{ lista_campos != "" ? "Editar |" : "Criar |" }} Evento
                    </h3>
                  </h3></v-card-title
                >
                <v-card-text>
                  <div class="row form-group pt-2">
                    <div class="col-md-6">
                      <label for="nome" class="col-md-12"
                        >Nome do Evento:*</label
                      >
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        :class="
                          form.nome_evento == ''
                            ? validate_form.nome_evento
                            : 'border-2 border-success'
                        "
                        v-model="form.nome_evento"
                        placeholder="Nome do evento..."
                      />
                    </div>
                    <div class="col-md-6">
                      <label for="sobrenome" class="col-md-12"
                        >Produtora ou Produtor:*</label
                      >
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        :class="
                          form.produtora_produtor == ''
                            ? validate_form.produtora_produtor
                            : 'border-2 border-success'
                        "
                        v-model="form.produtora_produtor"
                        placeholder="Nome do produtor ou produta..."
                      />
                    </div>

                    <div class="col-md-3 mt-2">
                      <label class="col-md-12"> Selecione data Evento</label>
                      <b-form-datepicker
                        required=""
                        id="example-datepicker"
                        :class="
                          form.data_evento == ''
                            ? validate_form.data_evento
                            : 'border-2 border-success'
                        "
                        v-model="form.data_evento"
                        class="mb-2"
                      ></b-form-datepicker>
                    </div>
                    <div class="col-md-3 mt-2">
                      <label class="col-md-12">Selecione Hora Evento</label>

                      <b-form-timepicker
                        required=""
                        :class="
                          form.horario_evento == ''
                            ? validate_form.horario_evento
                            : 'border-2 border-success'
                        "
                        v-model="form.horario_evento"
                        locale="en"
                      ></b-form-timepicker>
                    </div>

                    <div class="col-md-6 mt-2">
                      <label for="cpf" class="col-md-12">CPF ou CNPJ:*</label>
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        id="cpf"
                        :class="
                          form.cpf_cnpj == ''
                            ? validate_form.cpf_cnpj
                            : 'border-2 border-success'
                        "
                        v-mask="variableWithMask"
                        v-model="cpf_cnpj1"
                        placeholder="O cpf ou Cnpj"
                      />
                    </div>
                    <div class="col-md-2 mt-2">
                      <label for="cpf" class="col-md-12"
                        >Classificação etária:</label
                      >
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        id="classificacao"
                        :class="
                          form.classificacao == ''
                            ? validate_form.classificacao
                            : 'border-2 border-success'
                        "
                        v-model="form.classificacao"
                        placeholder="+18"
                      />
                    </div>
                    <!-- <div class="col-md-4 mt-2">
                    <label class="col-md-12">Genero:*</label>
                    <treeselect
                      required=""
                      :multiple="true"
                      :options="lista_categorias"
                      placeholder="Selecione o Genero..."
                      v-model="form_cat.categorias"
                      @input="onInput"
                    />
                  </div> -->
                  </div>
                </v-card-text>
              </v-card>
              <v-btn color="teal" class="mr-l" dark @click="validate(form, 2)">
                Continue
              </v-btn>
              <v-btn text> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">
              Cadastre Suas Informações pra Contato
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card color="grey lighten-1" class="mb-12" elevation="8">
                <v-card-title class="card-header">
                  <h3 class="mb-0">
                    <h3 class="mb-0">
                       {{ lista_campos != "" ? "Editar |" : "Criar |" }} Contato
                    </h3>
                  </h3></v-card-title
                >
                <v-card-text>
                  <div class="row form-group pt-2">
                    <div class="col-md-12">
                      <label>Email:*</label>
                      <input
                        required=""
                        placeholder="O seu E-mail"
                        v-model="form_contato.email"
                        class="form-control"
                        :class="
                          form_contato.email == ''
                            ? validate_form_contato.email
                            : 'border-2 border-success'
                        "
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-6">
                      <label>Nome Responsavel :*</label>
                      <input
                        required=""
                        placeholder="O seu Nome"
                        v-model="form_contato.nome"
                        :class="
                          form_contato.nome == ''
                            ? validate_form_contato.nome
                            : 'border-2 border-success'
                        "
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Sobrenome Responsavel Pelo Evento:*</label>
                      <input
                        required=""
                        placeholder="O seu Sobrenome"
                        v-model="form_contato.sobrenome"
                        :class="
                          form_contato.sobrenome == ''
                            ? validate_form_contato.sobrenome
                            : 'border-2 border-success'
                        "
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-6 mt-2">
                      <label for="cpf">CPF ou CNPJ:*</label>
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        id="cpf"
                        v-mask="variableWithMask2"
                        v-model="cpf_cnpj2"
                        :class="
                          form_contato.cpf == ''
                            ? validate_form_contato.cpf
                            : 'border-2 border-success'
                        "
                        placeholder="O cpf ou Cnpj"
                      />
                    </div>
                    <div v-show="!lista_campos.usuario" class="col-md-6">
                      <label> Login sem espacos:*</label>
                      <input
                        required=""
                        placeholder="O seu Login"
                        v-model="form_contato.login"
                        :class="
                          form_contato.login == ''
                            ? validate_form_contato.login
                            : 'border-2 border-success'
                        "
                        class="form-control"
                      />
                    </div>
                    <div v-show="!lista_campos.usuario" class="col-md-6">
                      <label>Senha para login:*</label>
                      <input
                        required=""
                        type="password"
                        placeholder="O sua senha"
                        v-model="form_contato.password"
                        :class="
                          form_contato.password == ''
                            ? validate_form_contato.password
                            : 'border-2 border-success'
                        "
                        class="form-control"
                      />
                    </div>

                    <div class="col-md-6 mt-2">
                      <label for="telefone1">Telefone :*</label>
                      <span>Obs: O 9 há mais ja colocado automaticamento </span>
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        id="telefone1"
                        v-mask="variableWithMaskTel"
                        :class="
                          form_contato.telefone1 == ''
                            ? validate_form_contato.telefone1
                            : 'border-2 border-success'
                        "
                        v-model="tel1"
                        placeholder="O telefone/celular..."
                      />
                      <v-radio-group v-model="radio" row>
                        <v-radio label="Brasil" value="1"></v-radio>
                        <v-radio label="Paraguai" value="2"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <label> Banco:*</label>
                      <input
                        required=""
                        placeholder="O nome do seu banco"
                        v-model="form_contato.banco"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-6">
                      <label> Nome na Conta do Banco:*</label>
                      <input
                        required=""
                        placeholder="O seu nome que conta no banco,chave pix ou cartão"
                        v-model="form_contato.nome_conta"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-4">
                      <label> Agência :*</label>
                      <input
                        required=""
                        placeholder="O sua agência"
                        v-model="form_contato.agencia"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-4">
                      <label> Numero da Conta :*</label>
                      <input
                        required=""
                        placeholder="O numero da sua conta"
                        v-model="form_contato.n_conta"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-4">
                      <label> pix :*</label>
                      <input
                        required=""
                        placeholder="Digite a chave pix"
                        v-model="form_contato.pix"
                        class="form-control"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-btn color="teal" dark @click="validate2()"> Continue </v-btn>
              <v-btn color="warning" @click="e6 = 1"> Voltar </v-btn>
              <v-btn text> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 3" step="3">
              Cadastre o Enderoço Do Evento
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card color="grey lighten-1" class="mb-12" elevation="8">
                <v-card-title class="card-header">
                  <h3 class="mb-0">
                    <h3 class="mb-0">
                       {{ lista_campos != "" ? "Editar |" : "Criar |" }} Endereço Do
                      Evento
                    </h3>
                  </h3></v-card-title
                >
                <v-card-text>
                  <!-- <div class="col-md-3">
              <label for="pais" class="col-md-12">País:*</label>
              <select
                v-model="form.pais_id"
                class="form-control"
              >
                <option></option>
                <option value="1">Brasil</option>
              </select>
            </div>
           

            -->
                  <div class="row form-group pt-2">
                    <div class="col-md-4">
                      <label class="col-md-12">Pais:*</label>
                      <treeselect
                        required=""
                        :multiple="false"
                        :options="[
                          { id: 1, label: 'Brasil' },
                          { id: 28, label: 'Paraguai' },
                        ]"
                        placeholder="Selecione o Pais..."
                        v-model="pais_id"
                        @input="onInput2"
                      />
                    </div>
                    <div v-if="pais_id != 28" class="col-md-4">
                      <label class="col-md-12">Estado:*</label>
                      <treeselect
                        required=""
                        :multiple="false"
                        :options="lista_estados"
                        placeholder="Selecione o Estado..."
                        v-model="form.estados_id"
                        @input="onInput"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="col-md-12">Cidade:*</label>
                      <treeselect
                        required=""
                        :multiple="false"
                        :options="lista_cidades"
                        placeholder="Selecione a Cidade..."
                        v-model="form.cidades_id"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-4">
                      <label class="col-md-12">CEP:*</label>
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        v-model="form.cep"
                        placeholder="cep ...."
                      />
                    </div>

                    <div class="col-md-6">
                      <label class="col-md-12">Endereço:*</label>
                      <input
                        required=""
                        type="text"
                        class="form-control"
                        v-model="form.endereco"
                        placeholder="Rua Bairro"
                      />
                    </div>

                    <div class="col-md-2">
                      <label for="endereco_numero" class="col-md-12"
                        >Numero:*</label
                      >
                      <input
                        required=""
                        type="number"
                        class="form-control"
                        id="endereco_numero"
                        v-model="form.endereco_numero"
                        placeholder="numero ...."
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <label for="nome_local_evento" class="col-md-12"
                        >Nome do Local do Evento:</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="nome_local_evento"
                        v-model="form.nome_local_evento"
                        placeholder="O complento..."
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-btn color="teal" dark @click="e6 = 4"> Continue </v-btn>
              <v-btn color="warning" @click="e6 = 2"> Voltar </v-btn>
              <v-btn text> Cancel </v-btn>
            </v-stepper-content>
            <v-stepper-step :complete="e6 > 4" step="4">
              Cadastre Ingressos Do Evento</v-stepper-step
            >
            <v-stepper-content step="4">
              <v-card color="grey lighten-1" class="mb-12" elevation="8">
                <v-card-title class="card-header row">
                  <div class="col-md-10 col-8">
                    <h3 class="mb-0">
                      <h3 class="mb-0">
                         {{ lista_campos != "" ? "Editar |" : "Criar |" }} Ingresso
                      </h3>
                    </h3>
                  </div>
                  <div class="col-md-2 col-3">
                    <v-btn
                      elevation="8"
                      outlined
                      @click="addIngresso()"
                      small
                      color="info"
                      light
                    >
                      <v-icon color="red" dense large>mdi-plus p</v-icon>
                    </v-btn>
                  </div>
                </v-card-title>
                <v-card-text style="border: 2px solid black">
                  <div
                    v-for="(item, index) in form_ingresso"
                    :key="index"
                    class="form-group row"
                  >
                    <div class="col-md-4">
                      <label class="col-md-12">Tipo:*</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="item.tipo"
                        placeholder="O tipo do Ingresso"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="col-md-12">Lote:*</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="item.lote"
                        placeholder="O lote do Ingresso"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="col-md-12">Valor:*</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="item.valor"
                        placeholder="O Valor do Ingresso"
                        @input="valorFormat(index)"
                        v-mask="variableWithMask3"
                      />
                    </div>

                    <hr v-show="form_ingresso.length - 1 != index" size="1" />
                  </div>
                </v-card-text>
              </v-card>
              <v-btn color="teal" dark @click="e6 = 5"> Continue </v-btn>
              <v-btn color="warning" @click="e6 = 3"> Voltar </v-btn>

              <v-btn text> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-step step="5">
              Cadastre Pontos de Vendas
            </v-stepper-step>
            <v-stepper-content step="5">
              <v-card color="grey lighten-1" class="mb-12" elevation="8">
                <v-card-title class="card-header row">
                  <div class="col-md-10 col-8">
                    <h3 class="mb-0">
                      <h3 class="mb-0">
                         {{ lista_campos != "" ? "Editar |" : "Criar |" }} PDV
                      </h3>
                    </h3>
                  </div>
                  <div class="col-md-2 col-3">
                    <v-btn
                      elevation="8"
                      outlined
                      @click="addPdv()"
                      small
                      color="info"
                      light
                    >
                      <v-icon color="red" dense large>mdi-plus p</v-icon>
                    </v-btn>
                  </div>
                </v-card-title>

                <v-card-text style="border: 2px solid black">
                  <div
                    v-for="(item, index) in form_pdv"
                    :key="index"
                    class="form-group row"
                  >
                    <div
                      class="col-md-12 pb-1"
                      :class="index == 0 ? 'pt-4' : ''"
                    >
                      <label class="col-md-12">Nome do pdv:*</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="item.nome"
                        placeholder="O pdv..."
                      />
                    </div>
                    <div class="col-md-10">
                      <label class="col-md-12">Endereco:*</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="item.endereco"
                        placeholder="O endereco..."
                      />
                    </div>
                    <div class="col-md-2">
                      <label class="col-md-12">numero:*</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="item.numero"
                        placeholder="O numero..."
                      />
                    </div>
                    <div class="col-md-12 mt-1">
                      <label class="col-md-12">Descrição:*</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="item.descricao"
                        placeholder="A descrição..."
                      />
                    </div>

                    <hr v-show="form_pdv.length - 1 != index" size="1" />
                  </div>
                </v-card-text>
              </v-card>

              <v-btn color="teal" dark @click="e6 = 1">
                Voltar Para o Começo
              </v-btn>
              <v-btn class="ml-1" color="warning" @click="e6 = 4">
                Voltar
              </v-btn>

              <v-btn
                color="primary"
                :disabled="verif"
                @click.prevent="confirm(lista_campos =='' ? 'cria' : 'edita')"
              >
                {{ lista_campos =='' ? "Salvar" : "Atualizar" }}

                <b-spinner
                  v-show="verif"
                  small
                  variant="dark"
                  type="grow"
                  label="Spinning"
                ></b-spinner
              ></v-btn>
            </v-stepper-content>
          </v-stepper>

          <!-- <v-card class="mt-5" elevation="8">
        <v-card-title class="card-header">
          <h3 class="mb-0">
            <h3 class="mb-0">
               {{ lista_campos != "" ? "Editar |" : "Criar |" }} Imagens E Video
            </h3>
          </h3></v-card-title
        >
        <div class="card-body table-responsive">
          <div class="col-md-9">
            <div class="row form-group">
              <InputFileSite text="Documento Banner Imagem*" ref="banner" />
              <div class="col-md-12 font-weight">
                Imagem Banner atual
                <v-img
                  max-height="300"
                  max-width="275"
                  :src="host + lista_campos.arquivos_id + '?token=' + token"
                ></v-img>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row form-group">
              <InputFileSite text="Documento Video " ref="video" />
              <div class="col-md-12 font-weight">
                Video atual
                <br />
                <iframe
                  v-if="lista_campos.videos_id"
                  height="400"
                  width="375"
                  :src="host + lista_campos.videos_id + '?token=' + token"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  {{ lista_campos == "" ? "Salvar" : "Atualizar" }}
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </div></div
      ></v-card> -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import IngressosRegras from "@/view/components/quil/IngressosRegra.vue";
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
// import InputFileSite from "@/core/helpers/InputFileSite";
import JwtService from "@/core/services/jwt.service";
import { t } from "caniuse-lite/data/browserVersions";
export default {
  // components: { InputFileSite },

  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      verif: false,
      form_cat: { categorias: null, eventos_id: null },

      form: {
        nome_evento: "",
        produtora_produtor: "",
        cpf_cnpj: "",
        endereco_numero: "",
        cep: "",
        endereco: "",
        nome_local_evento: "",
        data_evento: "",
        horario_evento: "",
        classificacao: "",
        status: 2,
        cidades_id: null,
        estados_id: null,
      },

      validate_form: {
        status: 2,
        nome_evento: null,
        produtora_produtor: null,
        cpf_cnpj: null,
        data_evento: null,
        horario_evento: null,
        classificacao: null,
      },
      validate_form_contato: {
        email: null,
        nome: null,
        sobrenome: null,

        cpf: null,
        login: null,
        password: null,
        telefone1: null,
      },

      form_contato: {
        email: "",
        nome: "",
        sobrenome: "",
        cpf: "",
        login: "",
        password: "",
        telefone1: "",
        n_conta: "",
        nome_conta: "",
        agencia: "",
        banco: "",
        pix: "",
      },
      e6: 1,
      form_ingresso: [],
      form_pdv: [],

      variableWithMask: "",
      variableWithMask2: "",
      variableWithMaskTel: "",
      variableWithMask3: "",

      paramestado: "",
      value: "",
      loading: false,
      cpf_cnpj1: null,
      cpf_cnpj2: null,
      tel1: null,
      radio: 1,
      pais_id: null,

      host: process.env.VUE_APP_API_URL + "arquivo/doc/",
      token: JwtService.getToken(),

      quillOptions: {
        placeholder: "Digite uma frase para personalizar",
        readonly: false,
      },
    };
  },

  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/logos/Logor.png";
    },
    evento() {
      return this.$store.state.evento.evento;
    },
    mensagem_alert() {
      return this.$store.state.evento.mensagem_alert;
    },
    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil;
    },
    // lista_campos() {
    //   return this.$store.state.configEmpresa.lista_campos;
    // },
    lista_estados() {
      return this.$store.state.endereco.lista_estados;
    },
    lista_cidades() {
      return this.$store.state.endereco.lista_cidades;
    },
    lista_categorias() {
      return this.$store.state.categoria.lista_categorias.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_campos() {
      return this.$store.state.evento.evento;
    },
  },
  created() {
    this.preenxerCampor();
    this.$store.dispatch("categoria/listar_categorias_site");
  },
  watch: {
    cpf_cnpj1: function () {
      this.variableWithMask =
        this.cpf_cnpj1.replace(/[^0-9]/g, "").length <= 11
          ? "###.###.###-##"
          : "##.###.###/####-##";
      this.form.cpf_cnpj = this.cpf_cnpj1.replace(/[^0-9]/g, "");
    },

    cpf_cnpj2: function () {
      this.variableWithMask2 =
        this.cpf_cnpj2.replace(/[^0-9]/g, "").length <= 11
          ? "###.###.###-##"
          : "##.###.###/####-##";
      this.form_contato.cpf = this.cpf_cnpj2.replace(/[^0-9]/g, "");
    },
    tel1: function () {
      this.variableWithMaskTel =
        this.radio == 1 ? "+55(##)9####-####" : "+595-###-######";

      this.form_contato.telefone1 = this.cpf_cnpj1.replace(/[^0-9]/g, "");
    },
  },
  methods: {
    validate(form, value) {
      let p = true;
      for (var k in this.validate_form) {
        //  console.log("Chave: " + k + ", Valor: " + this.form[k]);
        if (this.form[k] == "") {
          this.validate_form[k] = "border-2 border-danger";
        } else {
          this.validate_form[k] = "border-2 border-success";
        }
      }
      for (var j in this.validate_form) {
        if (this.validate_form[j] == "border-2 border-danger") {
          p = false;
          console.log(j);
        }
      }

      if (p == true) {
        this.e6 = 2;
      }
    },
    validate2() {
      let p = true;
      for (var k in this.validate_form_contato) {
        //  console.log("Chave: " + k + ", Valor: " + this.form[k]);

        if (this.form_contato[k] == "") {
          this.validate_form_contato[k] = "border-2 border-danger";
        } else {
          this.validate_form_contato[k] = "border-2 border-success";
        }

        if (this.lista_campos.usuario) {
          this.validate_form_contato[k] = "border-2 border-success";
        }
      }
      for (var j in this.validate_form_contato) {
        if (this.validate_form_contato[j] == "border-2 border-danger") {
          p = false;
          console.log(j);
        }
      }

      if (p == true) {
        this.e6 = 3;
      }
    },
    addIngresso() {
      this.form_ingresso.push({
        tipo: null,
        lote: null,
        valor: null,
        eventos_id: this.$route.params.eventos_id,
      });
    },
    valorFormat(index) {
      let pa = "";
      for (let i = 0; i < this.form_ingresso[index].valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask3 = "R$" + pa + "#,##";
    },

    async create_Ingresso() {
      this.verif = true;
      this.form_ingresso.forEach((element) => {
        element.valor = parseFloat(
          element.valor.replace("R$", " ").replace(",", ".")
        );
      });

      await this.$store.dispatch("ingressos/create_ingresso_evento_site", {
        id: this.$route.params.eventos_id,
        form: this.form_ingresso,
      });
      this.verif = false;
    },
    addPdv() {
      this.form_pdv.push({
        nome: null,
        endereco: null,
        numero: null,
        descricao: null,
      });
    },
    async create_pdv() {
      this.verif = true;
      await this.$store.dispatch("pdvsEvento/create_pdvs_evento_site", {
        id: this.$route.params.eventos_id,
        form: this.form_pdv,
      });
      this.verif = false;
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Evento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      // if (this.$refs.banner) {
      //   this.form.arquivos_id = await this.$refs.banner.submit();
      // }

      // if (this.$refs.video) {
      //   this.form.videos_id = await this.$refs.video.submit();
      // }

      await this.$store.dispatch("evento/create_evento_site", [
        this.form,
        this.form_contato,
      ]);
      this.fireAlert({
        ...this.mensagem_alert,
      });

      console.log(this.evento.id);
      this.form_cat.eventos_id = this.evento.id;
      await this.$store.dispatch(
        "categoria/vinculo_categoria_evento_site",
        this.form_cat
      );
      this.verif = false;

      // window.location.href = "https://boraticketstore.com.br";
    },
    async update() {
      // this.verif = true;
      // if (this.$refs.banner) {
      //   this.form.arquivos_id = await this.$refs.banner.submit();
      // }

      // if (this.$refs.video) {
      //   this.form.videos_id = await this.$refs.video.submit();
      // }

      this.create_Ingresso();
      this.create_pdv();
      await this.$store.dispatch("evento/update_evento_site", [
        this.form,
        this.form_contato,
      ]);
      this.fireAlert({
        ...this.mensagem_alert,
      });

      console.log(this.evento.id);
      this.form_cat.eventos_id = this.evento.id;
      await this.$store.dispatch(
        "categoria/vinculo_categoria_evento_site",
        this.form_cat
      );
      this.verif = false;

      // window.location.href = "https://boraticketstore.com.br";
    },

    // async update() {
    //   this.verif = true;
    //   if (this.$refs.banner) {
    //     this.form.arquivos_id = await this.$refs.banner.submit();
    //   }
    //   if (this.$refs.video) {
    //     this.form.videos_id = await this.$refs.video.submit();
    //   }
    //   await this.$store.dispatch("evento/update_evento", this.form);

    //   await this.$store.dispatch(
    //     "categoria/vinculo_categoria_evento",
    //     this.form_cat
    //   );

    //   this.verif = false;
    //   this.fireAlert({
    //     ...this.mensagem_alert,
    //     routeName: "evento",
    //   });
    // },
    onInput(value) {
      this.$store.dispatch("endereco/listar_cidades", value);
    },
    onInput2(value) {
      if (value != 28) this.$store.dispatch("endereco/listar_estados", value);
      else this.$store.dispatch("endereco/listar_cidades", value);
    },

    async preenxerCampor() {
      await this.$store.dispatch(
        "evento/listar_evento",
        this.$route.params.eventos_id
      );

      if (this.lista_campos != {}) {
        if (this.lista_campos.comp) {
          this.pais_id = this.lista_campos.comp.pais_id;
          this.$store.dispatch(
            "endereco/listar_cidades",
            this.lista_campos.evento.estados_id
          );
        }

        // console.log(
        //   this.lista_campos.categorias.map((cat) => cat.categoria_id)
        // );
        // this.form_cat.eventos_id = this.lista_campos.id;
        // this.form_cat.categorias = this.lista_campos.categorias.map(
        //   (cat) => cat.categoria_id
        // );
        this.form_pdv = this.lista_campos.pdvs;
        this.form_ingresso = this.lista_campos.íngressos;
        this.form = {
          id: this.lista_campos.evento.id,
          classificacao: this.lista_campos.evento.classificacao ?? "",
          nome_evento: this.lista_campos.evento.nome_evento ?? "",
          produtora_produtor: this.lista_campos.evento.produtora_produtor ?? "",
          endereco_numero: this.lista_campos.evento.endereco_numero ?? "",
          cep: this.lista_campos.evento.cep ?? "",
          endereco: this.lista_campos.evento.endereco ?? "",
          nome_local_evento: this.lista_campos.evento.nome_local_evento ?? "",
          data_evento: this.lista_campos.evento.data_evento ?? "",
          horario_evento: this.lista_campos.evento.horario_evento ?? "",
          cidades_id: this.lista_campos.evento.cidades_id ?? "",
          estados_id: this.lista_campos.evento.estados_id ?? "",
          cpf_cnpj: this.lista_campos.evento.cpf ?? "",
        };
        this.form_contato = {
          id: this.lista_campos.usuario.id,

          email: this.lista_campos.usuario.email ?? "",
          nome: this.lista_campos.usuario.nome ?? "",
          sobrenome: this.lista_campos.usuario.sobrenome ?? "",

          telefone1: this.lista_campos.usuario.telefone1 ?? "",
          cpf: this.lista_campos.usuario.cpf ?? "",
          n_conta: this.lista_campos.usuario.n_conta ?? "",
          nome_conta: this.lista_campos.usuario.nome_conta ?? "",
          agencia: this.lista_campos.usuario.agencia ?? "",
          banco: this.lista_campos.usuario.banco ?? "",
          pix: this.lista_campos.usuario.pix ?? "",
        };

        this.cpf_cnpj2 = this.lista_campos.usuario.cpf ?? "";
        this.cpf_cnpj1 = this.lista_campos.evento.cpf_cnpj ?? "";
      }
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
hr {
  border: 1px solid;
  background: #000;
  width: 100%;
}
.ba {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.9);
  /* background-image: url("https://boraticketstore.com.br/wp-content/themes/boraticket/assets/img/xfooter-bg.webp.pagespeed.ic.bRCEzz3dJO.webp"); */
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

</style>